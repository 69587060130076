export default [
  {
    id: 1,
    name: "The Distinguishing Characteristic of Being a Christian",
    ytID: "F4owOLTU0Fs"
  },
  {
    id: 2,
    name:
      "The First of Feasts, The Beginning of Redemption, and The Fear of Death",
    ytID: "cDeRlnAzSCI"
  },
  {
    id: 3,
    name: "The Coronavirus: A Sign of the Times",
    ytID: "_nxoCzOqjWU"
  },
  {
    id: 4,
    name:
      "The Triumphal Entry of Christ &amp; the Last Temptation of Christians",
    ytID: "KMdd94ir-sU"
  },
  {
    id: 5,
    name:
      "Holy Week in the Catacombs:<br/> Guides in the Quest for the Orthodox Ethos (pt. 1)",
    ytID: "STaEYHLcNcI",
    descriptions: true
  },
  {
    id: 6,
    name:
      "Holy Week in the Catacombs:<br/> Guides in the Quest for the Orthodox Ethos (pt. 2)",
    ytID: "hKku69HbbtY",
    descriptions: true
  },
  {
    id: 7,
    name: "The Coronavirus Crisis: Letter from the Holy Mountain",
    ytID: "K7uwpEUR5_c"
  },
  {
    id: 8,
    name:
      "Facing the Coronavirus Crisis with Faith Pleas from Mt. Athos and Greece",
    ytID: "JooWIeMV7kk"
  },
  {
    id: 9,
    name: "The Church is a Hospital, a Life-giving Spring",
    ytID: "dVL8PEiuP2s"
  },
  {
    id: 10,
    name: "Responding to the Crisis: Christian Realism and Worldly Idealism",
    ytID: "x3CHp1g7fyI"
  },
  {
    id: 11,
    name: "The Coronavirus Narrative and Its Demonic Methodology",
    ytID: "q5D7qbKzoaA"
  },
  {
    id: 12,
    name: "The “House Church,” Divine Liturgy and Us",
    ytID: "72YoEtGPRqY"
  },
  {
    id: 13,
    name: "On Demonic Methodology, Part II: Q &amp; A",
    ytID: "FsaxD2H6f1g"
  },
  {
    id: 14,
    name: `Our Spiritual and Ecclesiastical Crisis: <br/>An Interview with Professor Demetrios Tselengides (Pt. 1/3)`,
    ytID: "mx4U37GCSH8"
  },
  {
    id: 15,
    name: `Our Spiritual and Ecclesiastical Crisis: <br/>An Interview with Professor Demetrios Tselengides (Pt. 2/3)`,
    ytID: "tk6F6nyMzVY"
  },
  {
    id: 16,
    name: `On the Holy Temple of God &amp; COVID-19 Infection: <br/>Interview with Archimandrite Savas Agioreitis (Pt. 1/4)`,
    ytID: "5Bj-Y0mJjpc"
  },
  {
    id: 17,
    name: `Our Spiritual and Ecclesiastical Crisis: <br/>An Interview with Professor Demetrios Tselengides (Pt. 3/3)`,
    ytID: "DqHTCMRw_aM"
  },
  {
    id: 18,
    name: `The Role of the Spiritual Father in the Current Crisis: <br/>Interview with Archimandrite Savas Agioreitis (Pt. 2/4)`,
    ytID: "ZpzD5DaJ3Xs"
  },
  {
    id: 19,
    name: `How Shall We Live During This Current Persecution of Orthodoxy?: <br/>Interview with Archimandrite Savas Agioreitis (Pt. 3/4)`,
    ytID: "1qgv3lOLh2U"
  },
  {
    id: 21,
    name: `The Current Challenge of Caesaropapism to the Orthodox Faith: <br/>Interview with Archimandrite Savas Agioreitis (Pt. 4/4)`,
    ytID: "5M1v8XRJc_Q"
  },
  {
    id: 22,
    name: `The Method of Holy Communion & the Temptation of Little Faith: <br/>A Talk with Prof. Demetrios Tselengides`,
    ytID: "mk2G_KxgIA0"
  },
  {
    id: 23,
    name: `A Lack of Distinction Between the Theanthropic Body & the Body of Humanity: <br/>the Root of our Problems <br/>An Interview with Prof. Demetrios Tselengides`,
    ytID: "qt9jXE4aYz8"
  }
].filter(({ published }) => published !== false);
