import Driver from "driver.js";
import "driver.js/dist/driver.min.css";

export default () => {
  const driver = new Driver({
    allowClose: false,
    onReset: _ => {
      window.localStorage.setItem("driverDone", "yes");
    }
  });

  driver.defineSteps([
    {
      element: ".js-driver-nav",
      popover: {
        title: "Navigation Menu",
        description:
          "Choose to see a list of episodes, interactive transcript, list of chapters in the current episode or to download the transcript.",
        position: "bottom"
      }
    },
    {
      element: "#transcript-container",
      popover: {
        title: "Interactive transcript",
        description:
          "You can click on the text and the video will play from that point forward.",
        position: $(window).width() < 768 ? "top" : "left"
      }
    },
    {
      element: ".js-driver-search-single",
      popover: {
        title: "Seach inside single episode",
        description: `As you start typing search term, the results will appear on the left side. Use checkbox on the right side to toggle case sensitive search.`,
        position: "top"
      }
    },
    {
      element: ".js-driver-search-all",
      popover: {
        title: "Seach inside all episodes",
        description: `Type in your search term and press enter.`,
        position: "bottom-right"
      }
    },
    {
      element: ".js-clipboard",
      popover: {
        title: "Copy link",
        description: "Copy link at the current time (useful for sharing).",
        position: "bottom-right"
      }
    },
    {
      element: ".js-help",
      popover: {
        title: "Show this guide",
        position: "bottom-right"
      }
    },
    {
      element: ".js-driver-ytsub",
      popover: {
        title: "Subscribe",
        description: "Subscribe to The Orthodox Ethos YouTube channel.",
        position: "bottom-left"
      }
    }
  ]);

  $(".js-help").on("click", () => driver.start());
  window.localStorage.getItem("driverDone") !== "yes" && driver.start();
};
