import ClipboardJS from "clipboard";
import episodes from "./episodes";
import episodeTemplate from "./episodeTemplate";

let clipboard;

export default function loadEpisode(episodeId, startTime) {
  episodeId = episodeId || 1;
  if (startTime) startTime = Math.floor(startTime);
  const episode = episodes.find(({ id }) => id == episodeId) || episodes[0];
  window.history.pushState(
    {},
    "",
    `?ep=${episodeId}${startTime ? `&t=${startTime}` : ""}`
  );
  $(".js-episode-container")
    .empty()
    .html(episodeTemplate(Object.assign({}, episode, { startTime }), episodes));

  const player = new AblePlayer($("#thevideo"));
  const timer = setInterval(() => {
    if (player.playing) {
      clearTimeout(timer);
    } else {
      player.youTubePlayer?.playVideo && player.playMedia();
    }
  }, 1000);

  $("body").attr("data-episode", episodeId);

  $("a.js-episode")
    .off()
    .on("click", function () {
      const epId = Number($(this).attr("data-episode"));
      loadEpisode(epId);
    });

  // SEARCH
  $(".js-search_single input")
    .off()
    .on("keyup", function () {
      const searchTerm = $(this).val();
      if (searchTerm.length < 1) {
        $("#search_results-container").hide();
        return;
      }
      player.searchString = searchTerm;
      player.searchIgnoreCaps = $(".js-ignore-caps").prop("checked");
      player.showSearchResults();

      $(window).scrollTop(
        $("#search_results-container").show().offset().top - 200
      );
    });

  $(".js-ignore-caps")
    .off()
    .on("change", function () {
      player.searchIgnoreCaps = $(this).prop("checked");
      player.showSearchResults();
    });

  $(".js-search_single")
    .off()
    .on("submit", e => e.preventDefault())
    .get(0)
    .reset();
  $("#search_results-container").hide();
  // END SEARCH

  //Copy link:
  clipboard && clipboard.destroy();
  clipboard = new ClipboardJS(".js-clipboard", {
    text: _ => {
      UIkit.notification({
        message: "<span uk-icon='icon: check'></span> Link copied to clipboard",
        pos: "top-right",
        status: "success"
      });

      const ep = $("body").attr("data-episode");
      const time = Math.floor(player.youTubePlayer.getCurrentTime());
      console.log(time);
      return `${window.location.origin}?ep=${ep}&t=${time}`;
    }
  });

  return player;
}
