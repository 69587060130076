import episodes from "./episodes";
import loadEpisode from "./loadEpisode";

const parser = new WebVTT.Parser(window, WebVTT.StringDecoder());
const cachedData = [];

export default () => {
  const urls = episodes.map(({ id, descriptions }) => ({
    url: `data/ep${id}/${descriptions ? "descriptions" : "captions"}.vtt`,
    id
  }));

  $.when(
    ...urls.map(({ url, id }) =>
      $.get(url, data => {
        cachedData.push({ cues: data, id });
      })
    )
  ).done(() => {
    // Note: Array.sort mutates array in place
    cachedData.sort((a, b) => a.id - b.id);

    // GLOBAL SEARCH
    $("form.js-search_all").on("submit", function (e) {
      e.preventDefault();
      const searchString = $("input.js-search_all").val();
      if (!searchString) {
        $("#result-container").empty();
        return;
      }
      $("#result-container").html(
        '<div class="js-search_spinner" uk-spinner="ratio: 2"></div>'
      );
      setTimeout(() => {
        search(searchString);
      }, 500);
    });
  });
};

function search(searchString) {
  const $resultContainer = $("#result-container");
  const searchTerms = searchString.split(" ").filter(Boolean);

  parser.oncue = cue => {
    const terms = [
      ...new Set(
        searchTerms.filter(t =>
          cue.text.toLowerCase().includes(t.toLowerCase())
        )
      )
    ];

    terms.length > 0 &&
      $resultContainer.append(
        /*html*/
        `<div class="timed-cue" data-start-time="${cue.startTime}">
            ...${cue.text}...
          </div>`
      );
  };

  parser.onflush = () => {
    $(".js-search_spinner").remove();
    $resultContainer.mark(searchTerms, { exclude: ["h4", "h5"] });
    $(".timed-cue").on("click", function () {
      const startTime = $(this).attr("data-start-time");
      const episodeId = Number(
        $(this).prevAll("h5").first().attr("data-episode-id")
      );
      loadEpisode(episodeId, startTime);
    });
    if ($resultContainer.html() === "") {
      $resultContainer.html(
        '<div class="uk-text-lead" style="margin-top:50px;">No results found.</div>'
      );
    }
  };

  cachedData.forEach(({ cues, id }, i) => {
    // Headline
    if (searchTerms.some(t => cues.toLowerCase().includes(t.toLowerCase()))) {
      $resultContainer.append(
        /*html*/
        `<hr/>
        <h5 class="uk-margin-remove-bottom uk-margin-small-top" data-episode-id=${id}>Episode ${id}:</h5>
        <h4 class="uk-margin-remove-top">${
          episodes.find(episode => episode.id === id).name
        }</h4>`
      );
    }
    parser.parse(cues);
    // Flush when last cue is done
    if (i === cachedData.length - 1) parser.flush();
  });
}
