export default (
  {
    id,
    name,
    ytID,
    captions = true,
    chapters = true,
    descriptions,
    startTime = 0
  },
  episodes
) => /*html*/ `
<p class="uk-text-center uk-margin-remove">Episode ${id}:</p>
<h2 class="uk-heading-line uk-text-center uk-margin-remove-top">
  <span>${name}</span>
</h2>

<div class="uk-flex uk-flex-right">
    <ul class="uk-width-1-2@s uk-flex-right episode-nav js-driver-nav" uk-tab connect=".uk-switcher" animation="uk-animation-fade">
        <li><a href="#">Episodes</a></li>
        <li><a href="#">Transcript</a></li>
        <li><a href="#">Chapters</a></li>
        <li><a href="#">Download</a></li>
    </ul>
</div>

<div class="uk-flex flex-column-ats">
  <div class="uk-width-1-2@s uk-margin-right">
    <video
      id="thevideo"
      data-skin="2020"
      preload="auto"
      autoplay
      data-youtube-id="${ytID}"
      playsinline
      data-search-div="search_results-container"
      data-transcript-div="transcript-container"
      data-chapters-div="chapters-container"
      data-captions-position="below"
      data-use-chapters-button="false"
      data-use-descriptions-button="false"
      data-youtube-nocookie="true"
      data-start-time="${startTime}"
    >
      ${track(captions, "captions", id)}
      ${track(chapters, "chapters", id)}
      ${track(descriptions, "descriptions", id)}
    </video>
  </div>
  <div class="uk-width-1-2@s uk-card uk-card-default margin-top-ats">
    <ul class="uk-switcher uk-height-1-1">
      <li class="episodes-list">
        ${episodesList(episodes, id)}
      </li>
      <li class="uk-active">
        <div id="transcript-container"></div>
      </li>
      <li>
        <div id="chapters-container"></div>
      </li>
      <li class="uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
        <a href="data/ep${id}/transcript.pdf" target="_blank">
          <button class="uk-button uk-button-primary uk-margin">
            Download Transcript (PDF)
          </button>
        </a>
      </li>
    </ul>
  </div>
</div>
`;

function episodesList(episodes, activeEpisodeId) {
  const episodesMarkup = episodes
    .map(
      ({ id, name }) =>
        `<li class="${activeEpisodeId === id ? "active-episode" : ""}">
            <a class="uk-link-muted js-episode" data-episode="${id}">
                Episode ${id}: <i>${name}</i>
            </a>
        </li>`
    )
    .join("");

  return /*html*/ `
    <ul class="uk-list uk-list-striped">
        ${episodesMarkup}
    </ul>
  `;
}

function track(available, kind, id) {
  return available
    ? /*html*/ `<track kind="${kind}" src="data/ep${id}/${kind}.vtt" />`
    : "";
}
