import loadEpisode from "./loadEpisode";
import initSearch from "./search";
import driverGuide from "./driverGuide";

$(document).ready(() => {
  const qp = new URLSearchParams(window.location.search);
  const episode = qp.get("ep");
  const time = qp.get("t");
  loadEpisode(episode, time);

  initSearch();

  setTimeout(driverGuide, 2000);
});
